//BG
function showBg(e){
     
	if (e.key == "H") {
		document.body.classList.toggle("bg");
	}
    
}
 
addEventListener("keydown", showBg);

// Фиксируем хедер при скролле
// function fixedHeader() {
// 	const header = document.querySelector('header');

// 	const breakpoint = 300;
// 	if (window.scrollY >= breakpoint) {
// 		header.classList.add('fixed__nav');
// 	} else {
// 		header.classList.remove('fixed__nav');
// 	}
// }
// window.addEventListener('scroll', fixedHeader)


//ОН клик
// function share () {
// 	const toggle = document.querySelector('.share__toggle');

// 	toggle.addEventListener('click', function () {
// 		this.closest('.share').classList.toggle('open');
// 	})
// }

// share();

window.addEventListener("load", function(event) {
	//console.log('lazy');
	// window.lazyLoadInstance = event.detail.instance;
	var lazyLoadInstance = new LazyLoad({
	  // Your custom settings go here
	});
	// new VenoBox({
	//     selector: ".venobox"
	// });
	
});

//SWIPER
var swiperProducts = new Swiper(".swiper-product .swiper", {
    
  slideClass: 'p-card',
  navigation: {
      nextEl: ".sw-pagination .sw-next",
      prevEl: ".sw-pagination .sw-prev"
    },

  breakpoints: {
    // when window width is >= 320px
    300: {
        slidesPerView: 1,
        spaceBetween: 15,
    },
    500: {
        slidesPerView: 2,
        spaceBetween: 15,
    },
    769: {
        slidesPerView: 'auto',
        spaceBetween: 20,
    }
  }
});

var swiperReviews = new Swiper(".reviews .swiper", {
  slideClass: 'item',


      navigation: {
          nextEl: ".sw-pagination .sw-prev",
          prevEl: ".sw-pagination .sw-next"
        },
    
  //   thumbs: {
  //     swiper: swiperGalleryThumbs
  //   },
  
    breakpoints: {
      // when window width is >= 320px
      300: {
          slidesPerView: "auto",
          spaceBetween: 15,
      },
      1000: {
          slidesPerView: 4,
          spaceBetween: 20,
      }
    }
  });


// var swiperProductsMain = new Swiper(".producrs__swiper_auto", {
    
//     slidesPerView: "auto",
//     // slideClass: 'gallery-slide',
//   //   direction: 'horizontal',
//   //   pagination: {
//   //     el: ".swiper-pagination",
//   //   },
//       pagination: {
//       el: '.pagination_dots .dots',
//             clickable: true,
//           renderBullet: function (index, className) {
//               return '<span class="' + className + '"></span>';
//           },
//       },
//       navigation: {
//           nextEl: ".pagination .btn_prev",
//           prevEl: ".pagination .btn_next"
//         },
    
//   //   thumbs: {
//   //     swiper: swiperGalleryThumbs
//   //   },
  
//     breakpoints: {
//       // when window width is >= 320px
//       300: {
//           slidesPerView: "auto",
//           spaceBetween: 15,
//       },
//       1068: {
//           slidesPerView: 3,
//           spaceBetween: 30,
//       }
//     }
//   });

//   var swiperReviewsMain = new Swiper(".reviews__container ", {
    
//     slidesPerView: 1,
//       pagination: {
//       el: '.pagination_dots .dots',
//             clickable: true,
//           renderBullet: function (index, className) {
//               return '<span class="' + className + '"></span>';
//           },
//       },
//       navigation: {
//           nextEl: ".pagination .btn_prev",
//           prevEl: ".pagination .btn_next"
//         },
//   });

//   var swiperNewsMain = new Swiper(".news_main .news ", {
    
//     slidesPerView: 1,



//         breakpoints: {
//             // when window width is >= 320px
//             300: {
//                 slidesPerView: "auto",
//                 spaceBetween: 15,
//             },
//             769: {
//                 slidesPerView: 2,
//                 spaceBetween: 30,
//             }
//           }
//   });

//   var swiperEventsMain = new Swiper(".events_main .events", {
    
//     // slidesPerView: 4,
//     initialSlide:3,
//     // loop: true,
//     centeredSlides:true,
//     effect: "creative",
//     creativeEffect: {
//         prev: {
//             translate: ['-77.5%',-40,-400], //([horizontal, vertical, depth])
//         },
//         next: {
//             translate: ['77.5%',-40,-400], //([horizontal, vertical, depth])
//         },
//     },




//       pagination: {
//       el: '.pagination_dots .dots',
//             clickable: true,
//           renderBullet: function (index, className) {
//               return '<span class="' + className + '"></span>';
//           },
//       },
//       navigation: {
//           nextEl: ".events_main .events .btn_prev",
//           prevEl: ".events_main .events .btn_next"
//         },

//         breakpoints: {
//             // when window width is >= 320px
//             300: {
//                 slidesPerView: 1.1,
//                 // spaceBetween: 30,
//                 // initialSlide:1,
//             },
//             400: {
//                 slidesPerView: 1.3,
//                 spaceBetween: 30,
//                 initialSlide:1,
//             },
//             769: {
//                 slidesPerView: 1.88,
//                 spaceBetween: 30,
//                 initialSlide:1,
//             }
//           }
//   });