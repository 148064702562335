//Выезд меню

//Можно без JS с помощью чек бокса вместо кнопки
function openMenu () {
	const menuBtn = document.querySelector('.main-menu__btn');
	const mobileMenu = document.querySelector('.main-menu');

	menuBtn.addEventListener('click', function () {
		mobileMenu.classList.toggle('opened');
		document.body.classList.toggle('no-scroll');
		menuBtn.classList.toggle('main-menu__btn_active')
	})
}

openMenu();

//Открывание подменю в мобильной версии
//openSubmenuMenuMobile

// function openSubmenuMenuMobile() {
// 	const submenuParent = document.querySelectorAll('.main-menu__item_parent>a');

// 	submenuParent.forEach((t)=>{
// 		t.addEventListener('click', (e) => {
// 			e.preventDefault();
// 			var parentClassList = e.target.parentNode.classList;
// 			var submenuList = e.target.parentNode.querySelector('.main-menu__sub-list');
// 			if (!parentClassList.contains("opened")) {
// 				submenuList.style.height = `${ submenuList.scrollHeight }px`
// 			} else {
// 				submenuList.style.height = `${ submenuList.scrollHeight }px`;
// 		        window.getComputedStyle(submenuList, null).getPropertyValue("height");
// 		        submenuList.style.height = "0";
// 			}

// 			parentClassList.toggle('opened');
// 		})
// 	})
// }

// openSubmenuMenuMobile();