const miniCartBtn = document.querySelector('.mini-cart');
const miniCartCloseBtn = document.querySelector('.mini-cart_close');
const cartBlock = document.querySelector('.cart-container');

if (miniCartBtn) {
    miniCartBtn.addEventListener('click', toggleCart)
}
if (miniCartCloseBtn) {
    miniCartCloseBtn.addEventListener('click', toggleCart)
}
function toggleCart () {
    document.querySelector('.overlay').classList.toggle('opened');
    cartBlock.classList.toggle('opened')
}