//togglePanel

function togglePanel() {
	const panelTittle = document.querySelectorAll('.toggle-panel .toggle-panel__title');

	panelTittle.forEach((t)=>{
		t.addEventListener('click', (e) => {
			e.target.parentNode.classList.toggle('toggle-panel__item_active');

			var ctntBlock = e.target.parentNode.querySelector('.toggle-panel__content');

			if (e.target.parentNode.classList.contains("toggle-panel__item_active")) {
				ctntBlock.style.height = `${ ctntBlock.scrollHeight }px`
			} else {
				ctntBlock.style.height = `${ ctntBlock.scrollHeight }px`;
		        window.getComputedStyle(ctntBlock, null).getPropertyValue("height");
		        ctntBlock.style.height = "0";
			}
		})
	})
}

togglePanel();