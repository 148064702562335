//Выезд фильтра

function openFilter () {
	const filterBtn = document.querySelectorAll('.open_close_filter');
	const fiterBlock = document.querySelector('.filters');
    
    filterBtn.forEach(btn=>{
        btn.addEventListener('click', function () {
            fiterBlock.classList.toggle('opened');
            document.body.classList.toggle('no-scroll');
            // menuBtn.classList.toggle('main-menu__btn_active')
        })
    })
	
}

openFilter();